import { getBackendSrv, getDataSourceSrv } from '@grafana/runtime';
import { NotificationError } from 'components/shared/NotificationMessage';
import { API_MYSQL_REQUEST, API_DASHBOARD_UID, API_ALERTS_DASHBOARD_ID } from 'constants/api';
import { getSessionQuery } from './queries';

export const getDatasource = async (datasourceName: string) => {
  const datasource = await getDataSourceSrv()
    .get(datasourceName)
    .catch((err: any) => NotificationError(err));

  return datasource;
};

/** @return result.dashboard */
export const getDashboardByUid = async (uid: string) => {
  const result = await getBackendSrv()
    .get(`${API_DASHBOARD_UID}${uid}`)
    .catch((err: any) => NotificationError(err));
  return result.dashboard;
};

interface ApiResponse {
  results: any;
}

/** @return mysqlResults.results.tempvar.tables[0].rows*/
export const mysqlRequest = async (datasourceUid: string, query: string): Promise<any[]> => {
  const mysqlResults = await getBackendSrv().post<ApiResponse>(API_MYSQL_REQUEST, {
    queries: [
      {
        datasource: {
          type: 'mysql',
          uid: datasourceUid,
        },
        format: 'table',
        rawSql: query,
        refId: 'tempvar',
      },
    ],
  });

  const results = mysqlResults.results.tempvar.frames;
  if (results?.length) {
    const resultsArrays = results[0].data.values;
    if (!resultsArrays?.length) {
      return [];
    }
    const finalresult = resultsArrays[0]?.map(() => new Array(resultsArrays.length));
    for (const [indexArray, array] of resultsArrays.entries()) {
      for (const [indexValue, value] of array.entries()) {
        finalresult[indexValue][indexArray] = value;
      }
    }
    return finalresult;
  } else {
    return [];
  }
};

/** @return dictionary.data */
export const getJson = async (url: string) => {
  const dictionary = await getBackendSrv()
    .get(url)
    .catch((err: any) => NotificationError(err));
  return dictionary;
};

/** @return alerts.data */
export const getDashboardAlerts = async (dashboardId: number) => {
  const alerts = await getBackendSrv()
    .get(`${API_ALERTS_DASHBOARD_ID}${dashboardId}`)
    .catch((err: any) => NotificationError(err));

  return alerts;
};

/** @return number - total of beacon sessions */
export const getAllSessionsFromBeacon = async (datasourceUid: string, macAddress: string) => {
  let sqlRequest = getSessionQuery(macAddress);
  const queryResult = await mysqlRequest(datasourceUid, sqlRequest);
  const allSessions = queryResult.length;
  return allSessions;
};

/** @return number - last session id */
export const getLastTableId = async (tableName: string, datasourceUid: string, macAddress: string) => {
  let sqlRequest = `SELECT id FROM ${tableName} WHERE mac_address = '${macAddress}' ORDER BY id DESC LIMIT 1`;

  if (tableName === 'ai_session') {
    sqlRequest = `SELECT id FROM ai_session ORDER BY id DESC LIMIT 1`;
  }

  const queryResult = await mysqlRequest(datasourceUid, sqlRequest);
  if (!queryResult?.length) {
    return 0;
  }

  const id = queryResult[0][0] as number;
  if (!id) {
    return 0;
  }
  return id;
};

/** @return queryResult.results[0].series */
export const influxRequest = async (datasource: { name: string; id: number }, query: string) => {
  const urlQuery = `api/datasources/proxy/${datasource?.id}/query?db=${datasource.name}&q=${query}`;
  const queryResult = await getBackendSrv()
    .get(urlQuery)
    .catch((err) => {
      NotificationError(err);
    });
  return queryResult.results[0].series;
};
